var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('Parent'), _c('Table', {
    staticClass: "mt-3",
    attrs: {
      "headers": _vm.modifiedHeaders,
      "items": _vm.getShopFixedCostList,
      "total-records": _vm.getShopFixedCostList.length,
      "footer": false
    },
    on: {
      "update:options": _vm.fetchData
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('CardHeader', [_c('template', {
          slot: "left"
        }, [_c('div', {
          staticClass: "pl-5 pt-3 pb-2 d-flex align-center justify-center"
        }, [_c('v-card', {
          attrs: {
            "color": "#525252",
            "flat": "",
            "height": "29px",
            "width": "117px"
          }
        }, [_c('div', {
          staticClass: "d-flex justify-center align-center"
        }, [_c('v-icon', {
          attrs: {
            "size": "18"
          }
        }, [_vm._v("$costWhite")]), _c('div', {
          staticClass: "font-18px white--text pl-2"
        }, [_vm._v(" 月コスト ")])], 1)]), _c('div', {
          staticClass: "dropdown-main-div ml-3"
        }, [_c('FiscalYearDropDown', {
          on: {
            "updatedSelectedYear": _vm.updateData
          }
        })], 1)], 1)])], 2), _c('CardHeader', [_c('template', {
          slot: "left"
        }, [_c('div', {
          staticClass: "pa-5 d-flex justify-space-between align-center total-summary"
        }, [_c('div', {
          staticClass: "d-flex font-14px pl-1  main--text"
        }, [_c('div', {}, [_vm._v(" " + _vm._s(_vm.$t("page_fixed_labour_cost")) + " (" + _vm._s(_vm.$t("page_fixed_full_time")) + ") ")]), _c('div', {
          staticClass: "pl-2"
        }, [_vm._v(" ¥ " + _vm._s(_vm.getTotalCostRegularEmployees.toLocaleString()) + " ")])]), _c('div', {
          staticClass: "d-flex font-14px pl-1"
        }, [_c('div', {}, [_vm._v(" " + _vm._s(_vm.$t("page_fixed_rent")) + " ")]), _c('div', {
          staticClass: "pl-2"
        }, [_vm._v("¥ " + _vm._s(_vm.getTotalRent.toLocaleString()))])]), _c('div', {
          staticClass: "d-flex font-14px pl-1"
        }, [_c('div', {}, [_vm._v(" " + _vm._s(_vm.$t("page_fixed_PF_fee")) + " ")]), _c('div', {
          staticClass: "pl-2"
        }, [_vm._v(" ¥ " + _vm._s(_vm.getTotalPlatformFee.toLocaleString()) + " ")])]), _c('div', {
          staticClass: "d-flex font-14px pl-1"
        }, [_c('div', {}, [_vm._v(" " + _vm._s(_vm.$t("page_fixed_others")) + " ")]), _c('div', {
          staticClass: "pl-2"
        }, [_vm._v("¥ " + _vm._s(_vm.getTotalOthers.toLocaleString()))])])])])], 2)];
      },
      proxy: true
    }, {
      key: "item.year_month",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm._f("formatDate")(item.year_month, "YYYY/MM")) + " ")];
      }
    }, {
      key: "item.labor_cost_regular_employees",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" ¥ " + _vm._s(item.labor_cost_regular_employees > 0 ? item.labor_cost_regular_employees.toLocaleString() : "--") + " ")];
      }
    }, {
      key: "item.rent",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" ¥ " + _vm._s(item.rent > 0 ? item.rent.toLocaleString() : "--") + " ")];
      }
    }, {
      key: "item.platform_fee",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" ¥ " + _vm._s(item.platform_fee > 0 ? item.platform_fee.toLocaleString() : "--") + " ")];
      }
    }, {
      key: "item.others",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" ¥ " + _vm._s(item.others > 0 ? item.others.toLocaleString() : "--") + " ")];
      }
    }, {
      key: "item.edit",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('CostSetting', {
          attrs: {
            "item": item
          },
          on: {
            "reload-table": _vm.fetchData
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref7) {
              var on = _ref7.on,
                  attrs = _ref7.attrs;
              return [_c('div', _vm._g(_vm._b({
                staticClass: "d-flex justify-end pr-3"
              }, 'div', attrs, false), on), [_c('v-icon', _vm._g(_vm._b({}, 'v-icon', attrs, false), on), [_vm._v("$edit")])], 1)];
            }
          }], null, true)
        })];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }