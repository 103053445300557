<template>
  <v-dialog v-model="activate" width="355px">
    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope"
    /></template>
    <v-card class="font-roboto">
      <CardHeader>
        <template slot="left">
          <div class="pa-5 font-16px">
            {{ $t("page_fixed_cost_title") }}
          </div>
        </template>
      </CardHeader>
      <div class="d-flex justify-center pt-6 pb-10">
        <v-card class="font-14px px-2 mr-2" :elevation="0" outlined>
          {{ $t("page_fixed_cost_prev_month") }}
        </v-card>
        <v-btn
          color="primary"
          @click="fillPrevMonth"
          class="mr-5"
          :elevation="0"
          x-small
          >{{ $t("page_fixed_execution") }}</v-btn
        >
        <div class="font-14px mr-2">クリア</div>
        <v-icon size="16px" class="mouse-pointer" @click="clearFields"
          >$refresh</v-icon
        >
      </div>
      <v-form class="font-color">
        <div
          class="d-flex justify-center align-baseline"
          v-for="(field, index) in fields"
          :key="index"
        >
          <div class="text-6b6b7b mt-1 label pr-6 font-12px text-right">
            {{ field.label }} <br />
            {{ field.nextLineLabel }}
          </div>
          <InputCurrency
            class="pt-0 pr-7 font-12px"
            autocomplete="chrome-off"
            :custom="true"
            type="number"
            v-model.number="field.value"
          >
          </InputCurrency>
        </div>
      </v-form>
      <v-card-actions class="pb-9">
        <v-spacer></v-spacer>
        <v-btn
          class="mx-2 text-light-grey"
          @click="activate = false"
          depressed
          width="101px"
          height="31px"
          color="transparent"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          class="mx-2"
          @click="updateCost"
          color="primary"
          width="101px"
          height="31px"
          >{{ $t("page_cashbook_confirmation_register") }}</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import CardHeader from "@/components/admin/ui/CardHeader";
import InputCurrency from "@/components/admin/ui/Input/InputCurrency";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";
export default {
  props: {
    item: {
      required: true
    }
  },
  components: {
    CardHeader,
    InputCurrency
  },
  computed: {
    ...mapGetters(["getShopFixedCostList"])
  },
  data() {
    return {
      activate: false,
      isCreate: false,
      fields: {
        labor_cost_regular_employees: {
          label: this.$t("page_fixed_labour_cost"),
          nextLineLabel: `(${this.$t("page_fixed_full_time")})`,
          value: 0
        },
        rent: {
          label: this.$t("page_fixed_rent"),
          nextLineLabel: null,
          value: 0
        },
        platform_fee: {
          label: this.$t("page_fixed_PF_fee"),
          nextLineLabel: null,
          value: 0
        },
        others: {
          label: this.$t("page_fixed_others"),
          nextLineLabel: null,
          value: 0
        }
      }
    };
  },
  watch: {
    activate(val) {
      if (val) {
        for (let field in this.fields) {
          this.fields[field].value = this.item[field];
        }
        if (this.item.create !== undefined) {
          this.isCreate = this.item.create;
        } else {
          this.isCreate = false;
        }
      }
    }
  },
  methods: {
    clearFields() {
      for (let field in this.fields) {
        this.fields[field].value = 0;
      }
    },
    fillPrevMonth() {
      const prevMonthData = this.getShopFixedCostList.find(
        item =>
          item.year_month ===
          dayjs(this.item.year_month)
            .subtract(1, "month")
            .format("YYYY-MM")
      );
      if (prevMonthData !== undefined) {
        for (let field in this.fields) {
          this.fields[field].value = prevMonthData[field];
        }
      }
    },
    updateCost() {
      let emptyCounter = 0;
      let updated = {};
      for (let field in this.fields) {
        if (this.fields[field].value == 0) {
          emptyCounter++;
        } else {
          updated[field] = this.fields[field].value;
        }
      }
      if (emptyCounter === 0) {
        if (this.isCreate) {
          this.$store
            .dispatch("CREATE_SHOP_FIXED_COST", {
              shop_id: this.item.shop_id,
              year_month: this.item.year_month,
              ...updated
            })
            .then(() => {
              this.activate = false;
              this.$emit("reload-table");
            });
        } else {
          this.$store
            .dispatch("UPDATE_SHOP_FIXED_COST", {
              shop_id: this.item.shop_id,
              id: this.item.id,
              ...updated
            })
            .then(() => {
              this.$store.dispatch("ALERT", {
                show: true,
                text: "編集内容を保存しました。"
              });
              this.activate = false;
              this.$emit("reload-table");
            });
        }
      }
    }
  }
};
</script>
<style lang="scss" src="./style.scss" scoped></style>
