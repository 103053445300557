<template>
  <div class="">
    <Parent />
    <Table
      class="mt-3"
      :headers="modifiedHeaders"
      :items="getShopFixedCostList"
      :total-records="getShopFixedCostList.length"
      :footer="false"
      @update:options="fetchData"
    >
      <template v-slot:top>
        <CardHeader>
          <template slot="left">
            <div class="pl-5 pt-3 pb-2 d-flex align-center justify-center">
              <v-card color="#525252" flat height="29px" width="117px">
                <div class="d-flex justify-center align-center">
                  <v-icon size="18">$costWhite</v-icon>
                  <div class="font-18px white--text pl-2">
                    月コスト
                  </div>
                </div>
              </v-card>
              <div class="dropdown-main-div ml-3">
                <FiscalYearDropDown
                  @updatedSelectedYear="updateData"
                ></FiscalYearDropDown>
              </div>
            </div>
          </template>
        </CardHeader>
        <CardHeader>
          <template slot="left">
            <div
              class="pa-5 d-flex justify-space-between align-center total-summary"
            >
              <div class="d-flex font-14px pl-1  main--text">
                <div class="">
                  {{ $t("page_fixed_labour_cost") }} ({{
                    $t("page_fixed_full_time")
                  }})
                </div>
                <div class="pl-2">
                  ¥ {{ getTotalCostRegularEmployees.toLocaleString() }}
                </div>
              </div>
              <div class="d-flex font-14px pl-1">
                <div class="">
                  {{ $t("page_fixed_rent") }}
                </div>
                <div class="pl-2">¥ {{ getTotalRent.toLocaleString() }}</div>
              </div>
              <div class="d-flex font-14px pl-1">
                <div class="">
                  {{ $t("page_fixed_PF_fee") }}
                </div>
                <div class="pl-2">
                  ¥ {{ getTotalPlatformFee.toLocaleString() }}
                </div>
              </div>
              <div class="d-flex font-14px pl-1">
                <div class="">
                  {{ $t("page_fixed_others") }}
                </div>
                <div class="pl-2">¥ {{ getTotalOthers.toLocaleString() }}</div>
              </div>
            </div>
          </template>
        </CardHeader>
      </template>
      <template v-slot:item.year_month="{ item }">
        {{ item.year_month | formatDate("YYYY/MM") }}
      </template>
      <template v-slot:item.labor_cost_regular_employees="{ item }">
        ¥
        {{
          item.labor_cost_regular_employees > 0
            ? item.labor_cost_regular_employees.toLocaleString()
            : "--"
        }}
      </template>
      <template v-slot:item.rent="{ item }">
        ¥ {{ item.rent > 0 ? item.rent.toLocaleString() : "--" }}
      </template>
      <template v-slot:item.platform_fee="{ item }">
        ¥
        {{ item.platform_fee > 0 ? item.platform_fee.toLocaleString() : "--" }}
      </template>
      <template v-slot:item.others="{ item }">
        ¥ {{ item.others > 0 ? item.others.toLocaleString() : "--" }}
      </template>
      <template v-slot:item.edit="{ item }">
        <CostSetting :item="item" @reload-table="fetchData">
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex justify-end pr-3" v-bind="attrs" v-on="on">
              <v-icon v-on="on" v-bind="attrs">$edit</v-icon>
            </div>
          </template>
        </CostSetting>
      </template>
    </Table>
  </div>
</template>
<script>
import Parent from "@/views/admin/Store/Index";
import Table from "@/components/admin/partials/Table/Table";
import CardHeader from "@/components/admin/ui/CardHeader";
import CostSetting from "@/components/admin/partials/FixedCost/CostSetting";
import FiscalYearDropDown from "@/components/admin/ui/FiscalYearDropDown";
import { mapGetters } from "vuex";
export default {
  components: {
    Parent,
    Table,
    CardHeader,
    CostSetting,
    FiscalYearDropDown
  },
  computed: {
    ...mapGetters([
      "getShopFixedCostList",
      "getShop",
      "getTotalRent",
      "getTotalCostRegularEmployees",
      "getTotalOthers",
      "getTotalPlatformFee"
    ]),
    modifiedHeaders() {
      if (this.selectedYear === 2) {
        return [
          ...this.headers,
          {
            text: "",
            value: "",
            align: "center",
            sortable: false
          }
        ];
      } else {
        return [
          ...this.headers,
          {
            text: "",
            value: "edit",
            align: "center",
            sortable: false
          }
        ];
      }
    }
  },
  data() {
    return {
      selectedYear: null,
      year: null,
      headers: [
        {
          text: this.$t("page_fixed_table_moon"),
          value: "year_month",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("page_fixed_labour_cost"),
          value: "labor_cost_regular_employees",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("page_fixed_rent"),
          value: "rent",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("page_fixed_PF_fee"),
          value: "platform_fee",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("page_fixed_others"),
          value: "others",
          align: "center",
          sortable: false
        }
      ]
    };
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("FETCH_SHOP_FIXED_COST", {
        shop_id: this.$route.params.shop_id,
        year: this.year
      });
    },
    async updateData(year, data) {
      this.selectedYear = data.id;
      this.year = year;
      await this.fetchData();
    }
  }
};
</script>
<style lang="scss" src="./style.scss" scoped></style>
