var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "355px"
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(scope) {
          return [_vm._t(slot, null, null, scope)];
        }
      };
    })], null, true),
    model: {
      value: _vm.activate,
      callback: function callback($$v) {
        _vm.activate = $$v;
      },
      expression: "activate"
    }
  }, [_c('v-card', {
    staticClass: "font-roboto"
  }, [_c('CardHeader', [_c('template', {
    slot: "left"
  }, [_c('div', {
    staticClass: "pa-5 font-16px"
  }, [_vm._v(" " + _vm._s(_vm.$t("page_fixed_cost_title")) + " ")])])], 2), _c('div', {
    staticClass: "d-flex justify-center pt-6 pb-10"
  }, [_c('v-card', {
    staticClass: "font-14px px-2 mr-2",
    attrs: {
      "elevation": 0,
      "outlined": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("page_fixed_cost_prev_month")) + " ")]), _c('v-btn', {
    staticClass: "mr-5",
    attrs: {
      "color": "primary",
      "elevation": 0,
      "x-small": ""
    },
    on: {
      "click": _vm.fillPrevMonth
    }
  }, [_vm._v(_vm._s(_vm.$t("page_fixed_execution")))]), _c('div', {
    staticClass: "font-14px mr-2"
  }, [_vm._v("クリア")]), _c('v-icon', {
    staticClass: "mouse-pointer",
    attrs: {
      "size": "16px"
    },
    on: {
      "click": _vm.clearFields
    }
  }, [_vm._v("$refresh")])], 1), _c('v-form', {
    staticClass: "font-color"
  }, _vm._l(_vm.fields, function (field, index) {
    return _c('div', {
      key: index,
      staticClass: "d-flex justify-center align-baseline"
    }, [_c('div', {
      staticClass: "text-6b6b7b mt-1 label pr-6 font-12px text-right"
    }, [_vm._v(" " + _vm._s(field.label) + " "), _c('br'), _vm._v(" " + _vm._s(field.nextLineLabel) + " ")]), _c('InputCurrency', {
      staticClass: "pt-0 pr-7 font-12px",
      attrs: {
        "autocomplete": "chrome-off",
        "custom": true,
        "type": "number"
      },
      model: {
        value: field.value,
        callback: function callback($$v) {
          _vm.$set(field, "value", _vm._n($$v));
        },
        expression: "field.value"
      }
    })], 1);
  }), 0), _c('v-card-actions', {
    staticClass: "pb-9"
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "mx-2 text-light-grey",
    attrs: {
      "depressed": "",
      "width": "101px",
      "height": "31px",
      "color": "transparent"
    },
    on: {
      "click": function click($event) {
        _vm.activate = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("cancel")))]), _c('v-btn', {
    staticClass: "mx-2",
    attrs: {
      "color": "primary",
      "width": "101px",
      "height": "31px"
    },
    on: {
      "click": _vm.updateCost
    }
  }, [_vm._v(_vm._s(_vm.$t("page_cashbook_confirmation_register")))]), _c('v-spacer')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }